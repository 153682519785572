import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/aaron/Apps/fresh-pets/src/components/mdx-layout/mdx-layout.js";
import SEO from '../components/seo/seo.js';
import InstagramGrid from '../components/instagram-grid/instagram-grid.js';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title='Gallery' description='View our work in the Fresh Pets Gallery.' keywords={['Fresh', 'Pets', 'Gallery']} mdxType="SEO" />
    <h1>{`Gallery`}</h1>
    <h2>{`Look At These Puppers`}</h2>
    <InstagramGrid mdxType="InstagramGrid" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      